<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="4" :sm="24">
              <a-form-item label="模板编码">
                <a-input
                  v-model="queryParams.code"
                  placeholder="请输入模板编码"
                  @keyup.enter.native="handleQuery"
                  allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="模板名称">
                <a-input
                  v-model="queryParams.name"
                  placeholder="请输入模板名称"
                  @keyup.enter.native="handleQuery"
                  allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="短信API的模板编号">
                <a-input
                  v-model="queryParams.apiTemplateId"
                  placeholder="短信API的模板编号"
                  @keyup.enter.native="handleQuery"
                  allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="短信渠道">
                <a-select v-model="queryParams.channelCode" placeholder="请选择渠道" style="width: 100%">
                  <a-select-option v-for="(dict,index) in dict.type.extra_sms_channel" :key="index" :value="dict.value">
                    {{ dict.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item>
                <a-button type="primary" icon="a-icon-search" size="small" @click="handleQuery">搜索</a-button>
                <a-button icon="a-icon-refresh" size="small" @click="resetQuery">重置</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-row :gutter="10" class="mb8">
        <a-col :span="1.5">
          <a-button
            type="primary"
            plain
            icon="a-icon-plus"
            size="small"
            @click="handleAdd"
            v-hasPermi="['extra:template:add']"
          >新增
          </a-button>
        </a-col>
      </a-row>

      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :scroll="{ y: scrollY }"
        :size="tableSize"
        rowKey="code"
        :columns="columns"
        :data-source="templateList"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="applyStatus" slot-scope="text, record">
          <dict-tag :options="dict.type.extra_sms_template_type" :value="record.applyStatus"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type.sys_normal_disable" :value="record.status"/>
        </span>
        <span slot="channelId" slot-scope="text, record">
          <div>{{ formatChannelSignature(record.channelId) }}</div>
          <dict-tag :options="dict.type.extra_sms_channel" :value="record.channelCode"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleSendSms(record)" v-hasPermi="['extra:template:send-sms']">发送</a>
          <a-divider type="vertical"/>
          <a @click="handleUpdate(record)" v-hasPermi="['extra:template:edit']">修改</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record)"
          >
            <span slot="title">确认删除<b>{{ record.name }}</b>吗?</span>
            <a v-hasPermi="['extra:template:remove']">删除</a>
          </a-popconfirm>

        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParams.pageNum"
        :total="total"
        :page-size="queryParams.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!-- 添加或修改短信模板对话框 -->
    <a-drawer :visible.sync="open" width="700px" @close="cancel">
      <a-divider orientation="left">
        <b>{{ title }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="短信渠道编码" prop="channelId">
          <a-select v-model="form.channelId" placeholder="请选择短信渠道编号">
            <a-select-option
              v-for="channel in channelOptions"
              :key="channel.id"
              :value="channel.id">
              {{ channel.signature + '【' + selectDictLabel(dict.type.extra_sms_channel, channel.code) + '】' }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="短信类型" prop="type">
          <a-select v-model="form.type" placeholder="请选择渠道编码" clearable>
            <a-select-option
              v-for="dict in dict.type.extra_sms_template_type"
              :key="dict.value"
              :value="parseInt(dict.value)">
              {{ dict.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="模板编号" prop="code">
          <a-input v-model="form.code" placeholder="请输入模板编号"/>
        </a-form-model-item>
        <a-form-model-item label="模板名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入模板名称"/>
        </a-form-model-item>
        <a-form-model-item label="模板内容" prop="content">
          <a-input v-model="form.content" type="textarea" placeholder="请输入模板内容"/>
        </a-form-model-item>

        <a-form-model-item label="短信API的模板编号" prop="apiTemplateId">
          <a-input-number :min="1" v-model="form.apiTemplateId" placeholder="请输入短信 API 的模板编号" :precision="0" style="width: 100%"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="form.remark" placeholder="请输入备注"/>
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">确 定</a-button>
          <a-button @click="cancel">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>
    <!-- 对话框(发送短信) -->
    <a-modal title="发送短信" :visible.sync="sendSmsOpen" width="500px" append-to-body @cancel="cancelSendSms">
      <a-form-model ref="sendSmsForm" :model="sendSmsForm" :rules="sendSmsRules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="模板内容" prop="templateCode">
          <a-input v-model="sendSmsForm.content" type="textarea" placeholder="请输入模板内容" readonly />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input v-model="sendSmsForm.mobile" />
        </a-form-model-item>
        <a-form-model-item label="是否立即发送">
          <a-radio-group name="isSendNow" v-model="sendSmsForm.isSendNow" :default-value="1">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="2">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="sendSmsForm.isSendNow==2" label="发送时间" prop="sendTime">
          <a-date-picker v-model="sendSmsForm.sendTime" :disabled-date="disabledDate"
          :disabled-time="disabledDateTime" showTime format="YYYY-MM-DD HH:mm:ss" />
        </a-form-model-item>
        <a-form-model-item v-for="param in sendSmsForm.params" :key="param" :label="'参数 {' + param + '}'">
          <a-input v-model="sendSmsForm.templateParams[param]" :placeholder="'请输入 ' + param + ' 参数'" />
        </a-form-model-item>
      </a-form-model>
      <div slot="footer" class="dialog-footer">
        <a-button type="primary" @click="submitSendSmsForm">确 定</a-button>
        <a-button @click="cancelSendSms">取 消</a-button>
      </div>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import { listTemplate, getTemplate, delTemplate, addTemplate, updateTemplate, sendSms } from '@/api/sms/template'
import { getSimpleSmsChannels } from '@/api/sms/channel'
import { tableMixin } from '@/store/table-mixin'
import moment from 'moment';
export default {
  name: 'Template',
  dicts: ['sys_normal_disable', 'extra_sms_channel', 'extra_sms_template_type'],
  mixins: [tableMixin],
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 短信模板表格数据
      templateList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 短信渠道
      channelOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: null,
        status: null,
        code: null,
        name: null,
        content: null,
        contentParams: null,
        apiTemplateId: null,
        channelId: null,
        channelCode: null,
        isDeleted: null
      },
      columns: [
        {
          title: '模板编码',
          dataIndex: 'code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板名称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '模板内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '短信类型',
          dataIndex: 'type',
          align: 'center',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '开启状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '短信渠道',
          dataIndex: 'channelId',
          align: 'center',
          scopedSlots: { customRender: 'channelId' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: 180,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // 表单参数
      form: { status: 0 },
      // 表单校验
      rules: {
        type: [{ required: true, message: '短信类型不能为空', trigger: 'change' }],
        status: [{ required: true, message: '开启状态不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '模板编码不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '模板名称不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '模板内容不能为空', trigger: 'blur' }],
        apiTemplateId: [{ required: true, message: '短信 API 的模板编号不能为空', trigger: 'blur' }],
        channelId: [{ required: true, message: '短信渠道编号不能为空', trigger: 'change' }]
      },
      // 发送短信
      sendSmsOpen: false,
      sendSmsForm: {
        isSendNow:1, // 是否立即发送
        params: [] // 模板的参数列表
      },
      sendSmsRules: {
        mobile: [{ required: true, message: '手机不能为空', trigger: 'blur' }],
        templateCode: [{ required: true, message: '模版编码不能为空', trigger: 'blur' }],
        templateParams: {},
        sendTime: [{ required: true, message: '发送时间不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getList()
    // 获得短信渠道
    getSimpleSmsChannels().then(response => {
      this.channelOptions = response.data
    })
  },
  methods: {
    moment,
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    },
    /** 查询短信模板列表 */
    getList() {
      this.loading = true
      listTemplate(this.queryParams).then(response => {
        this.templateList = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        type: null,
        status: 0,
        code: null,
        name: null,
        content: null,
        contentParams: null,
        remark: null,
        apiTemplateId: null,
        channelId: null,
        channelCode: null,
        creator: null,
        createTime: null,
        modifier: null,
        updateTime: null,
        isDeleted: null
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
          pageNum: 1,
          pageSize: 10,
          type: null,
          status: null,
          code: null,
          name: null,
          content: null,
          contentParams: null,
          apiTemplateId: null,
          channelId: null,
          channelCode: null,
          isDeleted: null
      },
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加短信模板'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const id = row.id || this.ids
      getTemplate(id).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改短信模板'
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate(valid => {
          if (valid) {
            if (this.form.id != null) {
              updateTemplate(this.form).then(response => {
                if (response.success) {
                  this.$message.success('修改成功')
                  this.open = false
                  this.getList()
                } else {
                  this.$message.error(response.message)
                }
              })
            } else {
              addTemplate(this.form).then(response => {
                if (response.success) {
                  this.$message.success('新增成功')
                  this.open = false
                  this.getList()
                } else {
                  this.$message.error(response.message)
                }
              })
            }
          }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids
      delTemplate(ids).then(response => {
        if (response.success) {
          this.getList()
          this.$message.success('删除成功')
        } else {
          this.$message.error(response.message)
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('extra/template/export', {
        ...this.queryParams
      }, `template_${new Date().getTime()}.xlsx`)
    },
    /** 发送短息按钮 */
    handleSendSms(row) {
      this.resetSendSms(row)
      // 设置参数
      this.sendSmsForm.content = row.content
      this.sendSmsForm.params = row.params
      this.sendSmsForm.templateCode = row.code
      this.sendSmsForm.templateParams = row.params.reduce(function (obj, item) {
        obj[item] = undefined
        return obj
      }, {})
      // 根据 row 重置 rules
      this.sendSmsRules.templateParams = row.params.reduce(function (obj, item) {
        obj[item] = { required: true, message: '参数 ' + item + ' 不能为空', trigger: 'change' }
        return obj
      }, {})
      // 设置打开
      this.sendSmsOpen = true
    },
    /** 重置发送短信的表单 */
    resetSendSms() {
      // 根据 row 重置表单
      this.sendSmsForm = {
        content: undefined,
        params: undefined,
        mobile: undefined,
        templateCode: undefined,
        templateParams: {},
        sendTime: undefined
      }
      // this.resetForm("sendSmsForm");
    },
    /** 取消发送短信 */
    cancelSendSms() {
      this.sendSmsOpen = false
      this.resetSendSms()
    },
    /** 提交按钮 */
    submitSendSmsForm() {
        // 添加的提交
        this.$refs.sendSmsForm.validate(valid => {
          if (valid) {
            if(this.sendSmsForm.isSendNow == 2){
              this.sendSmsForm.sendTime = moment(this.sendSmsForm.sendTime).format('YYYY-MM-DD HH:mm:ss')
            }else{
              this.sendSmsForm.sendTime = undefined
            }
            sendSms(this.sendSmsForm).then(response => {
              if (response.success) {
                this.$message.success('提交发送成功！发送结果，见发送日志编号：' + response.data)
                this.sendSmsOpen = false
              } else {
                this.$message.error(response.message)
              }
            })
          }
        })
        
    },
    /** 格式化短信渠道 */
    formatChannelSignature(channelId) {
      for (const channel of this.channelOptions) {
        if (channel.id === channelId) {
          return channel.signature
        }
      }
      return '找不到签名：' + channelId
    }
  }
}
</script>
